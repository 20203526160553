import { Links, Meta, Outlet, Scripts } from '@remix-run/react';
import './styles/tailwind.css';
import { GTM_ID } from './constants/google';
import { useGtmInit, GtmNoScriptFallback } from '@hipagesgroup/utils';
import type { LinksFunction, MetaFunction } from '@remix-run/node'; // or cloudflare/deno

export const meta: MetaFunction = () => {
  return [
    { title: 'Get Quote (Up To 3 Providers) - Fast, Free & No Obligations' },
    {
      name: 'viewport',
      content: 'width=device-width,initial-scale=1',
    },
    {
      name: 'description',
      content:
        'Want to find tradies easily? Fill in our short form and get up to 3 quotes from vetted, expert licensed tradesmen for all your home service needs today.',
    },
    {
      rel: 'preload',
      as: 'script',
      href: `https://www.googletagmanager.com/gtm.js?id=${GTM_ID}`,
    },
  ];
};

export const links: LinksFunction = () => {
  return [
    {
      rel: 'icon',
      href: '/favicon.ico',
      type: 'image/ico',
    },
  ];
};

export default function App() {
  useGtmInit(GTM_ID);

  return (
    <html lang="en">
      <head>
        <Meta />
        <Links />
      </head>
      <body className="bg-surface text-content">
        <GtmNoScriptFallback gtmId={GTM_ID} />
        <Outlet />

        <Scripts />
      </body>
    </html>
  );
}
