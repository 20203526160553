export function addGtmScript(passedGtmId: string) {
  // Code copied from GTM console + added Typescript annotations.
  // See https://developers.google.com/tag-platform/tag-manager/datalayer
  (function (window: Window, document: Document, s: 'script', l: 'dataLayer', gtmId: string) {
    window[l] = window[l] || [];
    window[l]!.push({
      'gtm.start': new Date().getTime(),
      event: 'gtm.js',
    });

    const f = document.getElementsByTagName(s)[0];
    const j = document.createElement<'script'>(s);

    j.async = true;
    j.src = `https://www.googletagmanager.com/gtm.js?id=${gtmId}`;

    f.parentNode?.insertBefore(j, f);
  })(window, document, 'script', 'dataLayer', passedGtmId);
}
