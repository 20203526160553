import React from 'react';

export const GtmNoScriptFallback = ({ gtmId }: { gtmId: string }) => {
  return (
    <noscript>
      <iframe
        src={`https://www.googletagmanager.com/ns.html?id=${gtmId}`}
        className={'hidden invisible w-none h-none'}
        title="Google Tag Manager noscript fallback"
      ></iframe>
    </noscript>
  );
};
